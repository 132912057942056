
.banner {
    background: url(../bannerimages/newbanner.jpg) center/cover;
    background-position: contain;
    width: 100%;
    height: 600px;

}

.bannercontainer{
    height: 100%;
   display: flex;
   justify-content: start;
   align-items: center;
   padding-left: 5%;
   padding-top: 10%;

}
.Bannertext{
    margin-top: 2%;
    margin-left: 17%;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 50px;
    font-style: normal;
    color: #fdfdfd;
    font-style: italic;
}
#home-heading{
    font-size: 50px;
    text-align: center;
    font-weight: 600;
    margin-top: 3%;
}
#home-heading span{
    font-weight: 600;
}
.home-heading-para{
    font-size: 20px;
    font-weight: 300;
}
@keyframes fadeOut {
    from {
        opacity: 0;
        scale: 0.5;
    }
    to{
        opacity: 1;
        scale: 1;
    }
}
.Block{
    animation: fadeOut 2s linear;
    animation-timeline: view();
    animation-range: entry 0% cover 20%;
}
.home_card_1{
    background: url(../cardImages/1.png) no-repeat center center/cover;
    /* border-image: fill 0 linear-gradient(#0001,#000); */

}
.home_card_1:hover {
    cursor: pointer;
    border: 3px solid rgb(110, 110, 240);
}
.home_card_2{
    background: url(../cardImages/2.png) no-repeat center center/cover;
}
.home_card_2:hover {
    cursor: pointer;
    border: 3px solid rgb(110, 110, 240);
}
.home_card_3{
    background: url(../cardImages/specialities-card.png) no-repeat center center/cover;
}
.home_card_3:hover {
    cursor: pointer;
    border: 3px solid rgb(110, 110, 240);
}
.home_card_2_name{
    height: 80px;
    opacity: 0.5; 
    transition:  width 2s, height 2s, transform 2s; 
    overflow: hidden;
}
.home_card_2:hover .home_card_2_name{

    padding-top: 20px;
    height: 100%;
    opacity: 1;
}
.home_card_1:hover .home_card_2_name{
    padding-top: 20px;
    height: 100%;
    opacity: 1;
}
.home_card_3:hover .home_card_2_name{
    padding-top: 20px;
    height: 100%;
    opacity: 1;
}
.homeBanner2{
    background: url(../bannerimages/QuotesBanner.png) no-repeat center center/cover;
}
.home_cards{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 3%;
    flex-wrap: wrap;
    padding-bottom: 2%;
}
.home_card_1, .home_card_2, .home_card_3 {
    width: 300px;
    height: 350px;
    box-shadow: 10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 5%;
}
.home_card_2_name {
    width: 100%;
    border-radius: 0 0 10px 10px;
    background-color: transparent;
    backdrop-filter: blur(10px);
}
.home_card_2_name .home_card_2_name_title{
    color: white;
    font-weight: 600;
    font-size: 25px;
    margin: 0% 0 0 15px;
}

.exp{
    display: flex;
    justify-content: space-evenly;
    margin: 2% 13% 2% 13%;
    height: 80px;

}

.exp1{
    display: flex;
    width: 40%;
    padding: 20px;

}

.exp1 h1{
    font-weight: 700;
    color: #083b4a;
    font-size: 40px;
}

.exp1 p{
    margin: 25px 0 0 20px;
    font-weight: 400;
    font-size: 15px;
}
.homeBanner2{
    width: 100%;
    height: 250px;
}
.homeBanner2 h1{
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    padding: 3% 0 0 0;
    color: white;
}
.homeBanner2-heading{
    font-size: 50px;
    text-align: center;
    font-weight: 600;
    margin: 3% 0 0 0;

}



.splcontainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.splcontainer1, .splcontainer2, .splcontainer3{
    width: 25%;
    height: 650px;
    border: 1px solid gray;
    margin-top: 5%;
    border-radius: 10px;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.splimg{
    width: 100%;
    height: 300px;
    border-radius: 10px 10px 0 0 ;
}
.splcont{
    margin: 10px 30px;
}
.splcontainer button{
    width: 130px;
    height: 40px;
    margin: 20px 0% 0 0;
    border: none;
    border-radius: 10px;
    background-color: #CF7531;
    color: white;
    font-size: 13px;
    cursor: pointer;
}
.splcontainer button:hover{
    box-shadow: 2px 5px 10px #e3975c,-2px -5px 10px  #e3975c;
}
.splbtn{
    display: grid;
    place-items: center ;
}
.whychooseus-cont{
    width: 100%;
    padding: 5% 0 5% 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 2%;
    background-color: rgba(235, 235, 235, 0.449);
}
.whychooseus-cont-1{
    width: 25%;
    height: 250px;
    background-color: white;
    box-shadow: 10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    padding: 2%;
    cursor: pointer;
}
.whychooseus-cont-1 p{
    text-align: justify;
    font-size: 12px;
    margin-top: 3%;
}
.whychooseus-cont-1:hover{
    transform: scale(1.1);
    
}
.Whoweserve{
    width: 99vw;
    background-color: rgb(215, 215, 215);
    margin-top: 5%;
    padding: 2% 0 5% 0;
}
.Whoweserve h1{
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    color: #083b4a;
}
.Whoweserve button{
    height: 80px;
    border-radius: 5px;
    border: none;
    background-color: #243043;
    cursor: pointer;
    color: white;
    padding: 10px;
    font-size: 15px;
    text-align: center;
}
.Wwbut{
    display: flex;
    justify-content: space-evenly;
    margin-top: 1%;
}
.Whoweserve button:hover{
    border:  3px #083b4a solid;
    box-shadow: 7px 10px 20px white,-3px -10px 20px white;
}
.coreservices-title{
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    margin: 2% 0 0 0;
    color: #083b4a;
}
.cs1{
    width: 100%;
    
    padding: 0 0 3% 0;
    display: flex;
    justify-content: start;
}
.cs1-item1{
    width: 30%;

    padding: 1%;
}
.contcs1{
    width: 60%;

}
.coreservices img{
    width: 100%;
    height: 300px;
    border-radius: 10px;
    box-shadow: 10px 10px 20px rgb(140, 140, 140), -10px -10px 20px white    ;
}
.contcs1{
    margin: 2% 0% 2% 5%;
}
.coreservices h1{
    font-size: 40px;
    font-weight: 700;
    color: #243043;
}
.coreservices p{
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
}
.coreservices hr{
    height: 3px;
    background-color: gray;
    margin-top: 3%;
    width: 100%;
}
.coreservices button{
    width: 150px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color:#CF7531 ;
    color: white;
    margin-top: 2%;
}
.cs2{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 3% 0% 3% 0%;
    padding: 0 3%;

}
.contcs2{
    margin: 2% 0% 2% 0%;
    width: 60%;
}
.contcs2-1{
    width: 30%;
}
.cs3{
    display: flex;
    justify-content: start;
    width: 70%;
    margin: 4% 3%;
}
.Block{
    width:60%;
    height: 200px; 
    border:1px solid gray;
    padding: 10px;
    border-radius: 10px;
    background-color: transparant;
    backdrop-filter: blur(10px);
    position: relative ;
}
/* Default styles (for desktop) remain unchanged */

/* Media query for tablets and smaller screens */
@media (min-width: 990px) and (max-width: 1219px) {


    .banner{
        height: 500px;
    }
    .bannertext {
        margin-left: 10%;
        font-size: 35px;
    }

    .block {
        width: 50%;
        height: 150px;
    }


    .exp{
        display: flex;
        justify-content: space-evenly;
        margin: 2% 13% 2% 13%;
        height: 80px;
        
    }
    
    .exp1{
        display: flex;
        width: 40%;
        padding: 20px;
        
    }
    
    .exp1 h1{
        font-weight: 700;
        color: #083b4a;
        font-size: 40px;
    }
    
    .exp1 p{
        margin: 25px 0 0 20px;
        font-weight: 400;
        font-size: 15px;
    }

    .splcontainer {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .splcontainer1,
    .splcontainer2,
    .splcontainer3 {
        width: 90%;
        margin-top: 5%;
        height: auto;
    }

    .splimg {
        height: auto;
    }

    .Whoweserve {
        height: auto;
        padding: 5% 0;
    }

    .Wwbut {
        flex-direction: column;
        align-items: center;
    }

    .Wwbut a {
        width: 80%;
        margin-bottom: 10px;
    }

    .coreservices {
        flex-direction: column;
        align-items: center;
    }




    .contcs1,
    .contcs2 {
        margin: 2% 0 2% 0;
    }
    .cs2{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding: 5%;
    }
    .contcs2{
        width: 100%;
    }
    .contcs2-1{
        width: 100%;
    }
    

    .Whoweserve button {
        width: 100%;
        margin-top: 10px;
    }

    .home_card_1,
    .home_card_2,
    .home_card_3 {
        width: 90%;
        margin: 10px auto;
    }
}

/* Media query for mobile screens */
@media (min-width: 326px) and (max-width: 768px) {
    .banner {
        background: url(../bannerimages/newbanner.jpg) contain;
        background-position: contain;
        width: 100%;
        height: 200px;
    
    }
    .Bannertext {
        margin-left: 5%;
        font-size: 15px;
        margin-top: 0%;
    }
    .bannercontainer{
        padding-top: 10px;

    }
    .Block{
        width:60%;
        height: 70px; 
        border:1px solid gray;
        padding: 10px;
        border-radius: 10px;
        background-color: transparant;
        backdrop-filter: blur(10px);
        position: relative ;
    }

    .splcontainer1,
    .splcontainer2,
    .splcontainer3 {
        width: 100%;
        margin-top: 5%;
        height: auto;
    }
    .home_cards{
        padding: 0 5%;
    }
    .home_card_1, .home_card_2, .home_card_3 {
        width: 200px;
    }
    .home_card_2 .home_card_2_name_title{
        margin-top: 10%;
    }
    .home_card_2_name_title{
        font-size: 10px;
    }
    #home-heading{
        font-size: 15px;
    }
    #home-heading span{
        font-weight: 600;
    }
    .home-heading-para{
        font-size: 8px;
        font-weight: 300;
    }

    .exp {
        margin: 5% 3%;
        
    }

    .exp1 {
        width: 40%;
        display: block;

    }
    .exp1 h1{
        font-size: 15px;
    }
    
    .exp1 p{
        margin: 5% 0 0 0%;
        font-weight: 400;
        font-size: 8px;
    }
    .homeBanner2{
        width: 100%;
        height: 150px;
        padding: 10% 0 0 0;
        margin: 5% 0 0 0;
    }
    .homeBanner2 h1{
        font-weight: 600;
        font-size: 20px;
    }
    .homeBanner2-heading{
        font-size: 30px;
    }

    .Wwbut a {
        width: 100%;
    }

    .Whoweserve button {
        height: 60px;
        font-size: 5px;
    }
    .whychooseus-cont{
        width: 100%;
        padding: 5% 5% 5% 5%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2%;
        background-color: rgba(235, 235, 235, 0.449);
    }
    .whychooseus-cont-1{
        width: 80%;
        height: 250px;
        background-color: white;
        box-shadow: 10px 10px 20px #babecc, -10px -10px 20px #ffffff;
        padding: 5%;
        margin-top: 4%;
    }
    .whychooseus-cont-1 h4{
        text-align: center;
    }
    .whychooseus-cont-1 p{
        text-align: justify;
        font-size: 12px;
        margin-top: 3%;
    }
    
    .coreservices-title {
        font-size: 30px;
    }

    .coreservices {
        flex-direction: column;
        width: 100%;
    }
    .cs1{
        display: flex;
        flex-direction: column;
        padding: 5%;
    }
    .cs1-item1{
        width: 100%;
        padding: 1%;
    }
    .contcs1{
        width: 100%;
        margin: 2% 0% 2% 0%;
    }
    .cs2{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding: 5%;
    }
    .contcs2{
        width: 100%;
    }
    .contcs2-1{
        width: 100%;
    }
    .coreservices h1{
        font-size: 20px;
        font-weight: 600;
    }
    .coreservices p{
        font-size: 10px;
        font-weight: 300;
    }
    .coreservices img{
        height: 200px;
    }
    .coreservices button{
        width: 100px;
        height: 20px;
        border: none;
        color: white;
        border-radius: 2px;
        font-size: 10px;
        margin: 0 0 0 2%;
    }

    .home_card_1,
    .home_card_2,
    .home_card_3 {
        width: 100%;
    }

    .bannercontainer {
        padding-top: 10%;
        padding-left: 5%;
    }

    .splcontainer button {
        width: 100px;
        height: 40px;
    }

    .bannertext{
        font-size: 15px;
    }
}
@media (max-width : 325px){
        .banner {
        background: url(../bannerimages/newbanner.jpg) contain;
        background-position: contain;
        width: 100%;
        height: 100px;
    
    }
    .Bannertext {
        margin-left: 5%;
        font-size: 10px;
        margin-top: 0%;
    }
    .Block{
        width:60%;
        height: 50px; 
        border:1px solid gray;
        padding: 10px;
        border-radius: 10px;
        background-color: transparant;
        backdrop-filter: blur(10px);
        position: relative ;
    }

    .splcontainer1,
    .splcontainer2,
    .splcontainer3 {
        width: 100%;
        margin-top: 5%;
        height: auto;
    }
    .home_card_1, .home_card_2, .home_card_3 {
        width: 200px;
    }
    .home_card_2 .home_card_2_name_title{
        margin-top: 10%;
    }
    #home-heading{
        font-size: 15px;
    }
    #home-heading span{
        font-weight: 600;
    }
    .home-heading-para{
        font-size: 8px;
        font-weight: 300;
    }

    .exp {
        width: 100%;
        margin: 5% 3%;
        height: 50px;
    }

    .exp1 {
        width: 40%;

    }
    .exp1 h1{
        font-size: 10px;
    }
    
    .exp1 p{
        margin: 4% 0 0 2%;
        font-weight: 400;
        font-size: 5px;
    }
    .home_cards{
        padding: 0 5%;
    }
    .homeBanner2{
        width: 100%;
        height: 150px;
    }
    .homeBanner2 h1{
        font-weight: 600;
        font-size: 20px;
        padding: 10% 0 0 0;
    }
    .homeBanner2-heading{
        font-size: 30px;
    }

    .Wwbut a {
        width: 100%;
    }

    .Whoweserve button{
        height: 60px;
        font-size: 5px;
    }
    .Whoweserve h1{
        font-size: 30px;
    }
    .whychooseus-cont{
        width: 100%;
        padding: 5% 5% 5% 5%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2%;
        background-color: rgba(235, 235, 235, 0.449);
    }
    .whychooseus-cont-1{
        width: 80%;
        height: 250px;
        background-color: white;
        box-shadow: 10px 10px 20px #babecc, -10px -10px 20px #ffffff;
        padding: 5%;
        margin-top: 4%;
    }
    .whychooseus-cont-1 h4{
        text-align: center;
    }
    .whychooseus-cont-1 p{
        text-align: justify;
        font-size: 10px;
        margin-top: 3%;
    }

/* --------------------------------------------------------------------------- */
    .coreservices-title {
        font-size: 30px;
    }

    .coreservices {
        flex-direction: column;
        width: 100%;
    }
    .cs1{
        display: flex;
        flex-direction: column;
        padding: 5%;
    }
    .cs1-item1{
        width: 100%;
        padding: 1%;
    }
    .contcs1{
        width: 100%;
        margin: 2% 0% 2% 0%;
    }
    .coreservices h1{
        font-size: 20px;
        font-weight: 600;
    }
    .coreservices p{
        font-size: 10px;
        font-weight: 300;
    }
    .coreservices img{
        height: 200px;
    }
    .coreservices button{
        width: 100px;
        height: 20px;
        border: none;
        color: white;
        border-radius: 2px;
        font-size: 10px;
        margin: 0 0 0 2%;
    }

/* ------------------------------------------------------------------------------ */
.cs2{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: 5%;
}
.contcs2{
    width: 100%;
}
.contcs2-1{
    width: 100%;
}
    .home_card_1,
    .home_card_2,
    .home_card_3 {
        width: 100%;
    }

    .bannercontainer {
        padding-top: 10%;
        padding-left: 5%;
    }

    .splcontainer button {
        width: 100px;
        height: 40px;
    }
    .bannertext{
        font-size: 20px;
    }
}
@media (min-width: 1220px) and (max-width: 1440px) {

    .bannertext {
        margin-left: 10%;
        font-size: 35px;
    }
    .contcs2 h1{
        width: 100%;
    }
}
