.loaderpage{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderpage img{
    width: 500px;

}