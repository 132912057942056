
.carousal-session2{
    width: 100%;
    display: flex;
    justify-content:center ;
    padding-top: 2%;
    border: 1px solid gray;

}
.carousal-session2-1{
    width: 90%;
    background-color: #90909021;
    padding: 20px;
    border-radius: 10px;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    border: 1px solid rgba(177, 177, 177, 0.315);
}
.carousal-session2-1 h1{
    text-align: center;
}
.carousal-session2-1 p{
    text-align: justify;
    margin-top: 20px;
    font-size: 15px;
    text-indent: 50px;
}
.carousal-session2-1  li{
    list-style-type:disc;
    font-size: 12px;
    color: rgb(46, 46, 46);
    margin-top: 5px;
}
/* ----------------------------------------------------------- RCM CYCLE COMPONENT STYLE ----------------------------------------------------------------------- */

.RCMCycle-main-session{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;

}
.RCMCycle-left-session{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.RCM-diagram{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20% 0 20% 0;

}
.RCMCycle-right-session{
    width: 50%;
    padding: 70px 0 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RCMCycle-session1, .RCMCycle-session2, .RCMCycle-session3, .RCMCycle-session4, .RCMCycle-session5{
    width: 100%;
    height: 15%;
    display: flex;
}
.RCMCycle-left-session button{
    width: 200px;
    height: 100%;
    border-radius: 5px;
    border: 1px solid rgba(177, 177, 177, 0.315);
    background-color: #a9dbeb70;
    color: #243043;
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.RCMCycle-left-session button:hover {
    transform: scale(1.2);
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    background-color: #243043;
    color: white;
}
.RCMCycle-session1{
    justify-content: center;
}
.RCMCycle-session2{
    justify-content: space-between;
    padding: 0 12% 0 12%;
}
.RCMCycle-session3{
    justify-content: space-between;
    padding: 0 0% 0 0%;
}
.RCMCycle-session4{
    justify-content: space-between;
    padding: 0 12% 0 12%;
}
.RCMCycle-session5{
    justify-content: space-between;
    padding: 0 20% 0 20%;
}
.Mobile-RCM-content{
    display: none;
}

@media  (max-width: 1440px){
    .RCMCycle-left-session button{
        width: 150px;
        font-size: 12px;
    }

}
@media (min-width: 326px) and (max-width: 768px) {
    .Mobile-RCM-content{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 5%;
    }
    .demo-rcm-cycle{
        display: none;
    }
    .RCM-dsicription{
        font-size: 10px;
    }
}
@media (max-width : 325px){
    .Mobile-RCM-content{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 5%;
    }
    .demo-rcm-cycle{
        display: none;
    }
    .RCM-dsicription{
        font-size: 10px;
    }
}