.aboutus_banner {
    background: url(../bannerimages/Banner2.png) no-repeat center center/cover;
    width: 100vw;
    height: 400px;
    box-shadow: 5px 5px 10px gray;
}
.aboutus_banner img{
    margin-left: 65vw;
    margin-top: 10vh;
}
.demo{
    background-color: #be7b48;
}
/* ---------------Experience bar ---------------------------------------- */
.wwexp{
    display: flex;
    justify-content: space-around;
}
.wwexp1{
    display: flex;
    width: 40%;
    padding: 20px 20px 20px 20px ;
}

.wwexp1 p{
    margin: 25px 0 0 20px;
    font-weight: 400;
    font-size: 15px;
}
.wwexp1 h1{
    font-weight: 700;
    color: #083b4a;
    font-size: 40px;
}
#AboutUs{
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    color: #cf7531;
    margin-top: 3%;
}
.WhoweHerosec{
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.dbsection{
    width: 100%;
    height: 40px;
    border: none;
    box-shadow: 2px 5px 10px gray;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 5px;
    background-color: rgb(244, 244, 244);
    text-align: center;
    font-weight: 500;
    font-size: 12px;
}
.dbsection:hover{
    background-color: #243043;
    color: white;
}
.Whowe-nav {
    width: 20%;
    margin-top: 10px;
    max-height: 115vh; /* Limit the height to viewport */
    position: sticky;
    top: 20px; /* Sticks to the top of the viewport */
    padding: 4% 2% 0 2%;
    border-radius: 10px;
    background-color: rgb(224, 222, 222);
    box-shadow: 2px 5px 10px gray;
    overflow: hidden; /* Prevent scrollbar */
}

.Whowe-main{
    width: 80%;
    padding: 3% 5% 5% 5%;
    height: 100%;
}
/* -----------------------------------wwdiscription---------------------------------------------------------- */
.wwdiscription{
    display: flex;
    justify-content: space-between;

}
.wwdiscriptionsec1{
    width: 60%;
}
.wwdiscriptionsec1 p{
    text-align: justify;
}
.wwdiscriptionsec1 h1{
    font-size: 30px;
    font-weight: 600;
    color: #083b4a;
}
.wwdiscriptionsec2{
    width: 50%;
    display: flex;
    justify-content: end;
}
.wwdiscriptionsec2 img{
    width: 60%;
}
/* ----------------------------- Download broucher content part ---------------------------------------- */
.downloadcontainer{
    width: 100%;
    height: 250px;
    background-color: rgb(61, 61, 61);
    margin-top: 2%;
    padding-top: 2%;
}
.downloadcontainer button{
    width: 150px;
    height: 50px;
    border-radius: 30px;
}
.downloadcontainer button:hover{
    background-color: black;
    color: white;
}

/* ---------------------------- hero section Who we are discription ----------------------------------- */
.whowe-hero{
    height: 100%;
    padding: 0% 0% 2% 0%;
}
.herosec{
    display: flex;
    justify-content: space-between;
    margin: 5% 0% 8% 0%;
    height: 300px;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    background-color: rgb(230, 230, 230);
}
.hersec1{
    width: 40%;
}
.herosec1 img{
    margin: 10% 0% 0% 5%;
}
.herosec2{
    width: 60%;
    margin-top: 2%;
}
.herosec2 h1{
    text-align: center;
    font-size: 60px;
    font-weight: 600;
    
}
.herosec2 p{
    margin: 0 10%;
    text-align: justify;
    font-size: 15px;
}
.herosec2 p span{
    font-size: 20px;
    font-weight: 600;
}
.avan-dis{
    display: flex;
}
.avan-dis hr{
    width: 50px;
    height: 3px;
    background-color: #cf7531;
    margin: 20px 5px 0 0;
}
.avan-dis p{
    color: #cf7531;
    font-size: 20px;
    font-weight: 500;
}
/* --------------------------------------------- Misson and vission ------------------------------------- */
.mission-and-vission{
    display: flex;
    justify-content: space-between;
    margin: 10% 0 0 0;
}
.mission-and-vission h1{
    font-size: 60px;
    font-weight: 600;
    color: #083b4a;
}
.mission-and-vission p{
    margin-top: 20px;
}
.mission-cont{
    width:'140%'
}
.vission-cont h1{
    width: 100%;
    font-size: 60px;
    font-weight: 600;
    color: #083b4a;
}
.mission-icon, .vission-icon{
    width: 50px;
    height: 60px ;
    padding-top: 10px ;
}
.mission-img, .vission-img{
    width: 70% ;
    height: 250px;
    border-radius: 10px;
    box-shadow: 10px 10px 20px #babecc, -10px -10px 20px white;
}
#mission{
    width: 80%;
}
#vission{
    display: flex;
    justify-content: space-between;
    margin: 10% 0 0 0;
}
.vission-cont{
    width: 150%;
}
/* ---------------------------------------------Our firm is distinguished by its comprehensive ------------------------------------- */
.four-firm-heading{
    text-align: center ;
    margin-top: 5% ;
    font-size: 40px ;
    font-weight: 600 ;
    color: #083b4a ;
}
.portion4{
    width: 100%;
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
   
}
.por4{
    width: 30%;
    height: 150px;
    margin-top: 3%;
    background-color: #fad8bf9c;
    border-radius: 10px;
    box-shadow: 4px 6px 10px #753501;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1%;
    cursor: pointer;
}
.por4:hover h1{
    transform: scale(1.1);
    transition: transform 0.1s ease-in-out, color 0.1s ease-in-out;
}

.por4 h1{
    color: #CE762E;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    width: 100%;
}
/* -----------------------------------Enhancing Healthcare Efficiency with Advanced Analytics -------------------------------------------------------------------------------- */

.por5sec{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.por5sec1, .por5sec2, .por5sec3, .por5sec4{
    width: 45%;
    height: 200px;
    border: 1px solid rgb(175, 174, 174);
    border-radius: 10px;
    margin-top: 2%;
    display: flex;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.por5sec1img, .por5sec2img, .por5sec3img, .por5sec4img {
    width: 40%;
    height: 100%;
}
.por5sec1img img, .por5sec2img img, .por5sec3img img, .por5sec4img img{
    width: 100%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
}

.por5sec1cont, .por5sec2cont, .por5sec3cont, .por5sec4cont{
    width: 55%;
    height: 100%;
    margin: 3% 5% 0 4%;
}
.por5sec1cont h3, .por5sec2cont h3, .por5sec3cont h3, .por5sec4cont h3{
    font-weight: 600;
    color: #243043;
    font-size: 20px;
}
.por5sec1cont p, .por5sec2cont p, .por5sec3cont p, .por5sec4cont p{
    font-size: 13px;
    margin-top: 3%;
    text-align: justify;
}
.portion5-heading{
    text-align: center;
    font-size: 50px;
}
.portion5-subheading{
    margin-top: 2%;
    font-size: 20px;
}
/* ----------------------------------------------- Achieve Measurable, Proven Results --------------------------------------------------------- */
.percentage-portion{
    background-color: #083b4a;
    margin-top: 3%;
    padding: 3% 0 3% 0;
    width: 114%;
    margin-left: -7%;
}
.pp-heading{
    color: white;
    text-align: center;
    font-size: 50px;
}
.pp-sec{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    padding: 0% 5%;
   
}
.pp1{
    width: 30%;
    margin: 5% 3% 0 0 ;
    padding: 0 0 3% 0;


}
.pp1 h1, .pp1 p, .pp1 h3{
    text-align: center;
    color: white;
}
.pp1 p{
    font-size: 13px;
}
.pp1 h3{
    font-size: 25px;
    font-weight: 600;
}
.pp1 h1{
    font-size: 60px;
    font-weight: 600;
}
.pp1 h1 span{
    font-size: 60px;
    font-weight: 600;
    color: white;
}
.demo{
    background-color: #c3c3c3ae;
}
@media (min-width: 1200px) and (max-width: 1440px) {
    .dbsection{
        width: 100%;
        height: 40px;
        border: none;
        box-shadow: 2px 5px 10px gray;
        padding: 10px;
        margin: 10px 0px;
        border-radius: 5px;
        background-color: rgb(244, 244, 244);
        text-align: center;
        font-weight: 500;
        font-size: 10px;
    }
    .wwdiscriptionsec1 p{
        font-size: 14px;
    }
    .wwdiscriptionsec1 h1{
        font-size: 25px;
    }
    .mission-and-vission h1{
        font-size: 50px;
    }
    .mission-and-vission p{
        font-size: 15px;
        text-align: justify;
    }
    .mission-icon, .vission-icon{
        width: 40px;
        height: 60px ;
        padding-top: 20px ;
    }
    .mission-img, .vission-img{
        width: 80% ;
        height: 220px;
        border-radius: 10px;
        box-shadow: 10px 10px 20px #babecc, -10px -10px 20px white;
    }
    .four-firm-heading{
        font-size: 30px ;
    }
    .portion4{
        display: flex;
        justify-content:space-evenly;
        flex-wrap: wrap;
       
    }
    .por4sec{
        width: 40%;
        height: 200px;
        margin-top: 3%;
        background-color: #fad8bf9c;
        border-radius: 10px;
        box-shadow: 4px 6px 10px #753501;
    }
    .por4sec p{
        color: #550000;
        text-align: justify;
        margin: 5%;
        font-size: 13px;
    }
    .por4sec h1{
        color: #CE762E;
        font-weight: 600;
        margin-left: 5%;
        font-size: 20px;
    }
    .four-firm-icon{
        size: 60;
        width: 100px;
        margin-top: 5px;
    }
    .pp1 p{
        font-size: 13px;
    }
    .pp1 h3{
        font-size: 20px;
        font-weight: 600;
    }
    .pp1 h1{
        font-size: 50px;
        font-weight: 600;
    }
    .pp1 h1 span{
        font-size: 50px;
        font-weight: 600;
        color: white;
    }
    .por5sec1cont p, .por5sec2cont p, .por5sec3cont p, .por5sec4cont p{
        font-size: 12px;
        text-align: justify;
    }
    .por5sec1cont h3, .por5sec2cont h3, .por5sec3cont h3, .por5sec4cont h3{
        font-weight: 600;
        color: #243043;
        font-size: 18px;
    }
    .portion5-heading{
        text-align: center;
        font-size: 40px;
    }
    .portion5-subheading{
        margin-top: 2%;
        font-size: 18px;
    }
    .who-we-postion6 h1{
        font-size: 30px;
    }
    .who-we-postion6 p{
        font-size: 15px;
    }

  }
  @media (min-width: 326px) and (max-width: 768px) {
    .aboutus_banner {
        background: url(../bannerimages/Banner2.png) no-repeat center center/cover;
        width: 100%;
        height: 200px;
        box-shadow: 5px 5px 10px gray;
    }
    .aboutus_banner img{
        margin-left: 60%;
        margin-top: 5%;
        width: 30%;
    }
    .Whowe-nav{
        display: none;
    }
    .Whowe-main{
        width: 100%;
        padding: 3% 0 5% 0;
        height: 100%;
        border: 1px solid gray;
    }
    .wwexp1{
        display: flex;
        flex-direction: column;
        width: 30%;
        padding: 20px 0 20px 10px;
    }
    .wwexp1 p{
        margin: 10px 0 0 0px;
        font-weight: 400;
        font-size: 10px;
    }
    .wwexp1 h1{
        font-weight: 600;
        color: #083b4a;
        font-size: 15px;
    }
    #AboutUs{
        font-size: 30px;
        padding: 0 2%;
    }
.whowe-hero{
    height: 100%;
    padding: 0% 2% 2% 2%;
}
.herosec{
    display: flex;
    flex-direction: column;
    margin: 5% 0% 8% 0%;
    border-radius: 10px;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    background-color: rgb(230, 230, 230);
    padding: 0 0 5% 0;
}
.herosec1{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50%;
}
.herosec1 img{
    width: 80%;
    height: 100%;
    margin: 0% 0% 0% 0%;
}
.herosec2{
    width: 100%;
    margin-top: 2%;
}
.herosec2 h1{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    
}
.herosec2 p{
    margin: 0 10%;
    text-align: justify;
    font-size: 10px;
}
.herosec2 p span{
    font-size: 10px;
    font-weight: 600;
}
.avan-dis{
    display: none;
}
.wwdiscription{
    display: flex;
    flex-direction: column-reverse;

}
.wwdiscriptionsec1{
    width: 100%;
    padding: 0 5% 0 5%;
}
.wwdiscriptionsec1 p{
    text-align: justify;
    font-size: 10px;
}
.wwdiscriptionsec1 h1{
    font-size: 20px;
    font-weight: 600;
    color: #083b4a;
    text-align: center;
}
.wwdiscriptionsec2{
    width: 100%;
    padding: 0 5%;
}
.wwdiscriptionsec2 img{
    width: 100%;
    border-radius: 2%;
}
.mission-and-vission{
    display: flex;
    flex-direction: column;
    margin: 10% 0 0 0;
    padding: 0 5%;
}
.mission-img, .vission-img{
    width: 100% ;
    height: 250px;
    border-radius: 10px;
    box-shadow: 10px 10px 20px #babecc, -10px -10px 20px white;
}
#mission{
    width: 100%;
}
.mission-and-vission h1{
    font-size: 30px;
}
.mission-and-vission p{
    font-size: 10px;
    text-align: justify;
}
.mission-icon, .vission-icon{
    width: 20px;
    height: 40px ;
    padding-top: 20px ;
}
.mission-cont{
    width:'100%'
}
#vission{
    display: flex;
    flex-direction: column-reverse;
    padding: 0 5%;
}
.vission-cont h1{
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    color: #083b4a;
}
.vission-cont p{
    font-size: 10px;
    text-align: justify;
}
.vission-cont{
    width: 100%;
}
.por4{
    width: 40%;
    padding: 1% 5%;
}
.por4 h1{
    font-size: 15px;
}
.percentage-portion{
    background-color: #083b4a;
    margin: 3% 0 0 0;
    padding: 3% 0 3% 0;
    width: 100%;
}
.pp-heading{
    color: white;
    text-align: center;
    font-size: 20px;
    padding: 0 5%;
}
.pp-sec{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;       
}
.pp1{
    width: 40%;
    margin: 5% 3% 0 0 ;
    padding: 0 0 3% 0;
}
.pp1 h1, .pp1 p, .pp1 h3{
    text-align: center;
    color: white;
}
.pp1 p{
    font-size: 5px;
}
.pp1 h3{
    font-size: 10px;
    font-weight: 600;
}
.pp1 h1{
    font-size: 30px;
    font-weight: 600;
}
.pp1 h1 span{
    font-size: 30px;
    font-weight: 600;
    color: white;
}
.portion5{
    padding: 0 5%;
}
.portion5-heading{
    text-align: center;
    font-size: 20px;
}
.portion5-subheading{
    margin-top: 2%;
    font-size: 10px;
    text-align: justify;
}
.por5sec{
    display: flex;
    flex-direction: column;
}
.por5sec1, .por5sec2, .por5sec3, .por5sec4{
    width: 100%;
    border: 1px solid rgb(175, 174, 174);
    border-radius: 10px;
    margin-top: 2%;
    display: flex;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.por5sec1img, .por5sec2img, .por5sec3img, .por5sec4img {
    width: 40%;
    height: 100%;
}
.por5sec1img img, .por5sec2img img, .por5sec3img img, .por5sec4img img{
    width: 100%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
}

.por5sec1cont, .por5sec2cont, .por5sec3cont, .por5sec4cont{
    width: 55%;
    height: 100%;
    margin: 3% 5% 0 4%;
}
.por5sec1cont h3, .por5sec2cont h3, .por5sec3cont h3, .por5sec4cont h3{
    font-size: 15px;
}
.por5sec1cont p, .por5sec2cont p, .por5sec3cont p, .por5sec4cont p{
    font-size: 10px;
    margin-top: 3%;
    text-align: justify;
}
.who-we-postion6 h1{
    font-size: 20px;
}
.who-we-postion6 p{
    font-size: 10px;
}
.downloadcontainer{
    padding: 2% 0%;
}
.downloadcontainer button{
    width: 100px;
    height: 30px;
    border-radius: 20px;
    margin: 5% 0 0 0;
}
}
  @media (max-width : 325px){
    .aboutus_banner {
        background: url(../bannerimages/Banner2.png) no-repeat center center/cover;
        width: 100%;
        height: 100px;
        box-shadow: 5px 5px 10px gray;
    }
    .aboutus_banner img{
        margin-left: 60%;
        margin-top: 5%;
        width: 30%;
    }
    .Whowe-nav{
        display: none;
    }
    .Whowe-main{
        width: 100%;
        padding: 3% 0 5% 0;
        height: 100%;

    }
    .wwexp1{
        display: flex;
        flex-direction: column;
        width: 30%;
        padding: 20px 0 20px 10px;
    }
    .wwexp1 p{
        margin: 10px 0 0 0px;
        font-weight: 400;
        font-size: 10px;
    }
    .wwexp1 h1{
        font-weight: 600;
        color: #083b4a;
        font-size: 15px;
    }
    #AboutUs{
        font-size: 30px;
        padding: 0 2%;
    }
    .whowe-hero{
        height: 100%;
        padding: 0% 2% 2% 2%;
    }
    .herosec{
        display: flex;
        flex-direction: column;
        margin: 5% 0% 8% 0%;
        border-radius: 10px;
        box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
        background-color: rgb(230, 230, 230);
        padding: 0 0 5% 0;
    }
    .herosec1{
        width: 100%;
        display: flex;
        justify-content: center;
        height: 50%;
    }
    .herosec1 img{
        width: 80%;
        height: 100%;
        margin: 0% 0% 0% 0%;
    }
    .herosec2{
        width: 100%;
        margin-top: 2%;
    }
    .herosec2 h1{
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        
    }
    .herosec2 p{
        margin: 0 10%;
        text-align: justify;
        font-size: 10px;
    }
    .herosec2 p span{
        font-size: 10px;
        font-weight: 600;
    }
    .avan-dis{
        display: none;
    }
    .wwdiscription{
        display: flex;
        flex-direction: column-reverse;
    
    }
    .wwdiscriptionsec1{
        width: 100%;
        padding: 0 5% 0 5%;
    }
    .wwdiscriptionsec1 p{
        text-align: justify;
        font-size: 10px;
    }
    .wwdiscriptionsec1 h1{
        font-size: 20px;
        font-weight: 600;
        color: #083b4a;
        text-align: center;
    }
    .wwdiscriptionsec2{
        width: 100%;
        padding: 0 5%;
    }
    .wwdiscriptionsec2 img{
        width: 100%;
        border-radius: 2%;
    }
    .mission-and-vission{
        display: flex;
        flex-direction: column;
        margin: 10% 0 0 0;
        padding: 0 5%;
    }
    .mission-img, .vission-img{
        width: 100% ;
        height: 250px;
        border-radius: 10px;
        box-shadow: 10px 10px 20px #babecc, -10px -10px 20px white;
    }
    #mission{
        width: 100%;
    }
    .mission-and-vission h1{
        font-size: 30px;
    }
    .mission-and-vission p{
        font-size: 10px;
        text-align: justify;
    }
    .mission-icon, .vission-icon{
        width: 20px;
        height: 40px ;
        padding-top: 20px ;
    }
    .mission-cont{
        width:'100%'
    }
    #vission{
        display: flex;
        flex-direction: column-reverse;
        padding: 0 5%;
    }
    .vission-cont h1{
        width: 100%;
        font-size: 30px;
        font-weight: 600;
        color: #083b4a;
    }
    .vission-cont p{
        font-size: 10px;
        text-align: justify;
    }
    .vission-cont{
        width: 100%;
    }
    .four-firm-heading{
        font-size: 15px ;
        font-weight: 600 ;
        padding: 0 5%;
    }
    .por4{
        width: 40%;
        padding: 1% 5%;
    }
    .por4 h1{
        font-size: 15px;
    }
    .percentage-portion{
        background-color: #083b4a;
        margin: 3% 0 0 0;
        padding: 3% 0 3% 0;
        width: 100%;
    }
    .pp-heading{
        color: white;
        text-align: center;
        font-size: 20px;
        padding: 0 5%;
    }
    .pp-sec{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;       
    }
    .pp1{
        width: 40%;
        margin: 5% 3% 0 0 ;
        padding: 0 0 3% 0;
    }
    .pp1 h1, .pp1 p, .pp1 h3{
        text-align: center;
        color: white;
    }
    .pp1 p{
        font-size: 5px;
    }
    .pp1 h3{
        font-size: 10px;
        font-weight: 600;
    }
    .pp1 h1{
        font-size: 30px;
        font-weight: 600;
    }
    .pp1 h1 span{
        font-size: 30px;
        font-weight: 600;
        color: white;
    }
    .portion5{
        padding: 0 5%;
    }
    .portion5-heading{
        text-align: center;
        font-size: 20px;
    }
    .portion5-subheading{
        margin-top: 2%;
        font-size: 10px;
        text-align: justify;
    }
    .por5sec{
        display: flex;
        flex-direction: column;
    }
    .por5sec1, .por5sec2, .por5sec3, .por5sec4{
        width: 100%;
        border: 1px solid rgb(175, 174, 174);
        border-radius: 10px;
        margin-top: 2%;
        display: flex;
        box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    }
    .por5sec1img, .por5sec2img, .por5sec3img, .por5sec4img {
        width: 40%;
        height: 100%;
    }
    .por5sec1img img, .por5sec2img img, .por5sec3img img, .por5sec4img img{
        width: 100%;
        height: 100%;
        border-radius: 10px 0px 0px 10px;
    }
    
    .por5sec1cont, .por5sec2cont, .por5sec3cont, .por5sec4cont{
        width: 55%;
        height: 100%;
        margin: 3% 5% 0 4%;
    }
    .por5sec1cont h3, .por5sec2cont h3, .por5sec3cont h3, .por5sec4cont h3{
        font-size: 15px;
    }
    .por5sec1cont p, .por5sec2cont p, .por5sec3cont p, .por5sec4cont p{
        font-size: 10px;
        margin-top: 3%;
        text-align: justify;
    }
    .who-we-postion6 h1{
        font-size: 20px;
    }
    .who-we-postion6 p{
        font-size: 10px;
    }
    .downloadcontainer{
        padding: 2% 0%;
    }
    .downloadcontainer button{
        width: 100px;
        height: 30px;
        border-radius: 20px;
        margin: 5% 0 0 0;
    }

  }