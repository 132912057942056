.contactus-banner{
    background: url(../bannerimages/aboutusBanner.png) center/cover;
    width: 100vw;
    height: 400px;
    display: flex;
    justify-content: center;
    padding-top: 5%;
}
.contactus-banner h1{
    font-size: 50px;
    color: white;
    font-weight: 600;

}
.contactus-banner h3{
    text-align: center;
    color: white;
    font-weight: 400;
    margin-top: 10%;
}
.contactus-banner-num-mail{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 5%;
}
.contactus-banner-num-mail p{
    font-size: 12px;
}
.contact-icons{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 2%;
}
.title-portion{
    width: 100%;
    display: flex;
    justify-content: center;
}
.title-portion-1{
    width: 70%;
}
.title-portion-1 h1, .title-portion-1 h3{
    text-align: center;
    margin-top: 3%;
}
.title-portion-1 h3{
    font-size: 20px;
    margin-top: 2%;
}
.title-portion-1 p{
    text-align: justify;
    margin-top: 2%;
}

.portion0{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-around;
    margin: 5% 0 0 0;
}
.portion0-1{
    width: 40%;
    height: 100%;
    padding: 2% 0 0 0;
}
.portion0-1 p{
    text-align: justify;
}
.portion0-2{
    width: 40%;
    height: 100%;
    margin-right: 5%;
}
.portion0-2 img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;

}
.portion1{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.portion1-1, .portion1-22{
    width: 50%;
    height: 100%;
}
.portion1-1{
    display: flex;
    justify-content: center;
    padding: 5%;
}
.address-sec{
    width: 100%;
    height: 100%;

}
.address{
    margin: 2% 0 0 2%;
}

.why-reach-out{
    margin: 2% 0 0 2%;
}
.why-reach-out p{
    margin-top: 3%;
}
.why-reach-out span{
    font-weight: 600;
    font-size: 20px;
}

.follow-us img{
    width: 40px;
    height:40px;
}
@media (min-width: 326px) and (max-width: 768px) {
    .contactus-banner{
        background: url(../bannerimages/aboutusBanner.png) center/cover;
        width: 100vw;
        height: 200px;
        padding-top: 5%;
    }
    .contactus-banner h1{
        font-size: 35px;
    }
    .contactus-banner h3{
        font-size: 20px;
        margin: 5% 0 0 0;
    }
    .contactus-banner-num-mail{
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 1%;
    }
    .contactus-banner-num-mail p{
        font-size: 10px;
    }
    .contact-icons{
        display: none;
    }
    .contdetail{
        font-size: 10px;
    }
    .title-portion{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 5%;
    }
    .title-portion-1{
        width: 100%;
    }
    .title-portion-1 h3{
        font-size: 15px;
        margin-top: 2%;
    }
    .title-portion-1 p{
        text-align: justify;
        margin-top: 2%;
        font-size: 10px;
    }
    .portion0{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 5% 0 0 0;
        padding: 0 5% 5% 5%;
        height: 300px;
    }
    .portion0-1{
        width: 100%;
        height: 100%;
        padding: 2% 0 0 0;
    }
    .portion0-2{
        width: 100%;
        height: 100%;
        margin-right: 0%;
        padding: 0 5%;
    }
    .portion0-2 img{
        box-shadow: none;

    }
    .portion0-1 p{
        font-size: 10px;
    }
    .portion1{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 5%;
    }
    .portion1-1, .portion1-22{
        width: 100%;
        height: 100%;
    }
}
@media (max-width : 325px){
    .contactus-banner{
        background: url(../bannerimages/aboutusBanner.png) center/cover;
        width: 100vw;
        height: 100px;
        padding-top: 5%;
    }
    .contactus-banner h1{
        font-size: 20px;
    }
    .contactus-banner h3{
        font-size: 10px;
        margin: 2% 0 0 0;
    }
    .contactus-banner-num-mail{
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 1%;
    }
    .contactus-banner-num-mail p{
        font-size: 5px;
    }
    .contact-icons{
        display: none;
    }
    .contdetail{
        font-size: 10px;
    }
    .title-portion{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 5%;
    }
    .title-portion-1{
        width: 100%;
    }
    .title-portion-1 h3{
        font-size: 15px;
        margin-top: 2%;
    }
    .title-portion-1 p{
        text-align: justify;
        margin-top: 2%;
        font-size: 10px;
    }
    .portion0{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 5% 0 0 0;
        padding: 0 5% 5% 5%;
        height: 300px;
    }
    .portion0-1{
        width: 100%;
        height: 100%;
        padding: 2% 0 0 0;
    }
    .portion0-2{
        width: 100%;
        height: 100%;
        margin-right: 0%;
        padding: 0 5%;
    }
    .portion0-2 img{
        box-shadow: none;

    }
    .portion0-1 p{
        font-size: 10px;
    }
    .portion1{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 5%;
    }
    .portion1-1, .portion1-22{
        width: 100%;
        height: 100%;
    }


}