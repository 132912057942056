.whatwedo-banner{
    background: url(../bannerimages/whatwedo-banner.jpg)  center/cover;
    width: 99vw;
    height: 400px;
    border-image: fill 0 linear-gradient (#0001, #111);
    box-shadow: inset 0px 0px 100px 100px rgba(0, 0, 0, 0.7);
}
.whatwedo-banner h1{
    color: #ffffff;
    text-align: center;
    font-size: 50px;
    padding: 200px;
}
.whatwedo-banner span{
    color: white;
    font-size: 30px;
}
.whatwedo-dis{
    display: flex;
    justify-content: space-around;
    margin-top: 2%;
    padding: 0px 5%;
}
.whatwedo-dis-sec1-1, .whatwedo-dis-sec1-2{
    width: 40%;

}
.wwd-dis-heading h3{
    font-size: 30px;
    margin: 0 0 0% 2%;
}
.whatwedo-dis-sec1-1 h2{
    width: 90%;
}
.whatwedo-dis-sec1-2 p{
    margin: 5%;
    text-align: justify;
}
.wwd-dis-heading{
    display: flex;
    margin-top: 5%;
}
.wwd-sec2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.wwd-sec2-1, .wwd-sec2-2,.wwd-sec2-3, .wwd-sec2-4, .wwd-sec2-5, .wwd-sec2-6, .wwd-sec2-7, .wwd-sec2-8{
    width: 30%;
    /* height: 380px; */
    margin-top: 5%;
    box-shadow:10px 10px 10px  #babecc, -10px -10px 10px #ffffff;
    border: 1px solid rgb(212, 211, 211);
    border-radius: 5px;
    background-color: #a9dbeb70;
    padding: 0 0 1% 0;
}
.wwd-sec2-1-cont{
    font-size: 13px;
    margin: 3% 5%;
    text-align: justify;
}
.wwd-card-icon{
    width: 50px;
    color: blue;
}
.wwd-sec2-1 h3, .wwd-sec2-2 h3,.wwd-sec2-3 h3, .wwd-sec2-4 h3, .wwd-sec2-5 h3, .wwd-sec2-6 h3, .wwd-sec2-7 h3, .wwd-sec2-8 h3{
    font-size: 20px;
    font-weight: 600;
    margin-left: 5%;
    color: #243043;
}
.wwd-sec2-1:hover, .wwd-sec2-2:hover,.wwd-sec2-3:hover, .wwd-sec2-4:hover, .wwd-sec2-5:hover, .wwd-sec2-6:hover, .wwd-sec2-7:hover, .wwd-sec2-8:hover{
 transition: 0.5s ease;
 transform: scale(1.1);
 cursor: pointer;
}
.whatwedo-dis-sec3{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    padding: 0px 5% 0 5%;
}

.whatwedo-dis-sec3-1{
    width: 40%;
    height: 100%;
}
.whatwedo-dis-sec3-2{
    width: 50%;
}

/* -------------------------------------------------------------- Consulting package page ---------------------------------------------------------------- */

.Advantages-3{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center ;
    padding: 0 10% 0 10%;
}
.Advantages-btn-1, .Advantages-btn-2, .Advantages-btn-3{
    width: 30%;
    height: 150px;
    border-radius: 10px;
    background-color: #f3a76d52;
    padding: 10px 0 0 30px;
}
.Advantages-btn-1 p, .Advantages-btn-2 p, .Advantages-btn-3 p{
    /* margin-top: 10px; */
}
.Advantages-btn-1 h1, .Advantages-btn-2 h1, .Advantages-btn-3 h1{
    font-size: 50px;
}
.consulting-left-session{
    width: 20%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    box-shadow: 10px 10px 20px #babecc85, -10px -10px 20px #ffffff;
    position: -webkit-sticky; /* For Safari */
    position: sticky; /* Sticky positioning */
    top: 0; /* Stick to the top */
    overflow: hidden; 
    height: 100vh;
    padding-top: 100px;
}

.consulting-right-session{
    width: 80%;
    padding-left: 20px;

}
.consulting-right-session p{
    text-align: justify;
}
.consulting-right-session hr{
    margin-top: 3%;
}
.offer-content{
    width: 80%;
}
.consulting-right-session-title{
    text-align: center;
    margin:2% 10% 0 10%;
    font-size: 40px;
    color: #083b4a;
}

.consulting-right-session-content-title{
    font-size: 20px;
    color: #cf7531;
    font-weight: 600;
}

.consulting-left-session a{
    width: 100%;
    height: 10%;
    margin: 5% 0 0 0;
    transition: background-color 0.3s ease;
}

.consulting-left-session button{
    width: 100%;
    height: 100%;
}

.consulting-left-session button:hover {
    background-color: #243043;
    color: white;
    font-weight: 600;
}
/* ----------------------------------------------------------------- RCM cycle session style ---------------------------------------- */

.demo-rcm-cycle{
    width: 100%;
    height: 70vh;
}
.demo-rcm-cycle-sec1, .demo-rcm-cycle-sec2, .demo-rcm-cycle-sec3, .demo-rcm-cycle-sec4, .demo-rcm-cycle-sec5{
    height: 15%;
}
.demo-rcm-cycle-sec1{
    display: flex;
    justify-content: center;
}
.demo-rcm-cycle-arrow{
    height: 5%;

}
.demo-rcm-cycle h1{
    font-size: 25px;
}
.demo-rcm-cycle p{
    font-size: 12px;
}
.demo-rcm-cycle h5{
    color: white;
    font-size: 15px;
    font-weight: 600;
}

.Beni-verify{
    width: 200px;
    border: 1px solid #243043;
    border-radius: 10px;
    background-color: #243043;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.Beni-verify-but{
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.Beni-verify:hover {
    box-shadow:10px 10px 20px #353536, -10px -10px 20px #ffffff;
    
}
.Beni-verify-but:hover {
    transform: scale(1.1);    
}
.Beni-verify-dis{
    width: 400px;
    position: absolute;
    border: 1px solid rgba(177, 177, 177, 0.315);
    border-radius: 10px;
    left: 60vw;
    display: none;
    padding: 1%;
    background-color: #f4f4f4;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}

.Beni-verify:hover .Beni-verify-dis{
    display: block;
}
.demo-rcm-cycle-sec2, .demo-rcm-cycle-sec3, .demo-rcm-cycle-sec4{
    display: flex;
    justify-content: space-between;
}
.demo-rcm-cycle-sec2{
    padding: 0 28%;
}
.patient-state{
    width: 200px;
    border: 1px solid #243043;
    border-radius: 10px;
    background-color: #243043;
    cursor: pointer;

}
.patient-state-but{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
}
.patient-state:hover {
    box-shadow:10px 10px 20px #353536, -10px -10px 20px #ffffff;
    
}
.patient-state-but:hover {
    transform: scale(1.1);    
}
.patient-state-dis{
    width: 400px;
    position: absolute;
    border: 1px solid rgba(177, 177, 177, 0.315);
    border-radius: 10px;
    left: 1vw;
    display: none;
    padding: 1%;
    background-color: #f4f4f4;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.patient-state:hover .patient-state-dis{
    display: block;
}
.medicode{
    width: 200px;
    border: 1px solid #243043;
    border-radius: 10px;
    background-color: #243043;
    cursor: pointer;

}
.medicode-but{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
}
.medicode:hover {
    box-shadow:10px 10px 20px #353536, -10px -10px 20px #ffffff;
    
}
.medicode-but:hover {
    transform: scale(1.1);
}
.medicode-dis{
    width: 350px;
    position: absolute;
    border: 1px solid rgba(177, 177, 177, 0.315);
    border-radius: 10px;
    left: 72vw;
    display: none;
    padding: 1%;
    background-color: #f4f4f4;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.medicode:hover .medicode-dis{
    display: block;
}

.demo-rcm-cycle-sec3{
    padding: 0 20%;
}
.Ar-manage{
    width: 200px;
    border: 1px solid #243043;
    border-radius: 10px;
    background-color: #243043;
    cursor: pointer;

}
.Ar-manage-but{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    
}
.Ar-manage:hover {
    box-shadow:10px 10px 20px #353536, -10px -10px 20px #ffffff;
    
}
.Ar-manage-but:hover {
    transform: scale(1.1);    
}
.Ar-manage-dis{
    width: 400px;
    position: absolute;
    border: 1px solid rgba(177, 177, 177, 0.315);
    border-radius: 10px;
    left: 1vw;
    display: none;
    padding: 1%;
    background-color: #f4f4f4;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.Ar-manage:hover .Ar-manage-dis{
    display: block;
}
.rcm-circle{
    position: relative;
    width: 200px;
    height: 200px;
    background-color: #243043;
    border: 1px solid #243043;
    top: -100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: white;
    font-weight: 600;
}

.Demo-charge{
    width: 200px;
    border: 1px solid #243043;
    border-radius: 10px;
    background-color: #243043;
    cursor: pointer;

}
.Demo-charge-but{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;

}
.Demo-charge:hover {
    box-shadow:10px 10px 20px #353536, -10px -10px 20px #ffffff;
    
}
.Demo-charge-but:hover {
    transform: scale(1.1);    
}
.Demo-charge-dis{
    width: 300px;
    position: absolute;
    border: 1px solid rgba(177, 177, 177, 0.315);
    border-radius: 10px;
    left: 75vw;
    display: none;
    padding: 1%;
    background-color: #f4f4f4;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.Demo-charge:hover .Demo-charge-dis{
    display: block;
}


.demo-rcm-cycle-sec4{
    padding: 0 28%;
}
.Pay-post{
    width: 200px;
    border: 1px solid #243043;
    border-radius: 10px;
    background-color: #243043;
    cursor: pointer;

}
.Pay-post-but{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
}
.Pay-post:hover {
    box-shadow:10px 10px 20px #353536, -10px -10px 20px #ffffff;
    
}
.Pay-post-but:hover {
    transform: scale(1.1);    
}
.Pay-post-dis{
    width: 400px;
    position: absolute;
    border: 1px solid rgba(177, 177, 177, 0.315);
    border-radius: 10px;
    left: 1vw;
    display: none;
    padding: 1%;
    background-color: #f4f4f4;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.Pay-post:hover .Pay-post-dis{
    display: block;
}
.claim-sub{
    width: 200px;
    border: 1px solid #243043;
    border-radius: 10px;
    background-color: #243043;
    cursor: pointer;

}
.claim-sub-but{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    
}
.claim-sub:hover {
    box-shadow:10px 10px 20px #353536, -10px -10px 20px #ffffff;
    
}
.claim-sub-but:hover {
    transform: scale(1.1);    
}
.claim-sub-dis{
    width: 350px;
    position: absolute;
    border: 1px solid rgba(177, 177, 177, 0.315);
    border-radius: 10px;
    left: 72vw;
    display: none;
    padding: 1%;
    background-color: #f4f4f4;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.claim-sub:hover .claim-sub-dis{
    display: block;
}

.demo-rcm-cycle-sec5{
    display: flex;
    justify-content: center;
}
.ERA{
    width: 200px;
    border: 3px solid #243043;
    border-radius: 10px;
    background-color: #243043;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.ERA-but{
    width: 100%; 
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ERA:hover {
    box-shadow:10px 10px 20px #353536, -10px -10px 20px #ffffff;
    
}
.ERA-but:hover { 
    transform: scale(1.1);   
}
.ERA-dis{
    width: 400px;
    position: absolute;
    border: 1px solid rgba(177, 177, 177, 0.315);
    border-radius: 10px;
    left: 60vw;
    display: none;
    padding: 1%;
    background-color: #f4f4f4;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}

.ERA:hover .ERA-dis{
    display: block;
}

@media (min-width: 1200px) and (max-width: 1440px){
    
    
    .whatwedo-banner span{
        font-size: 25px;
    }
    .wwd-card-icon{
        width: 40px;
        color: blue;
    }
    .wwd-sec2-1 h3, .wwd-sec2-2 h3,.wwd-sec2-3 h3, .wwd-sec2-4 h3, .wwd-sec2-5 h3, .wwd-sec2-6 h3, .wwd-sec2-7 h3, .wwd-sec2-8 h3{
        font-size: 18px;
        font-weight: 600;
        margin-left: 5%;
        color: #243043;
    }

}
@media (min-width: 326px) and (max-width: 768px) {
    
    .whatwedo-banner{
        background: url(../bannerimages/whatwedo-banner.jpg)  center/cover;
        width: 100%;
        height: 200px;
    }
    .whatwedo-banner h1{
        color: white;
        text-align: center;
        font-size: 30px;
        padding: 10% 0 0 0;
    }
    .whatwedo-banner span{
        color: white;
        font-size: 10px;
    }
    
    .whatwedo-dis{
        display: flex;
        flex-direction: column;
        margin-top: 2%;
        padding: 0px 5%;
    }
    .whatwedo-dis-sec1-1, .whatwedo-dis-sec1-2{
        width: 100%;
    }
    .wwd-dis-heading h3{
        font-size: 20px;
        margin: 0 0 0 2%;
        text-align: justify;
    }
    .whatwedo-dis-sec1-1 h2{
        width: 100%;
        font-size: 15px;
    }
    .whatwedo-dis-sec1-2 p{
        margin: 0%;
        text-align: justify;
        font-size: 10px;
    }
    .wwd-sec2{
        padding: 0 5%;
    }
    .wwd-sec2-1, .wwd-sec2-2,.wwd-sec2-3, .wwd-sec2-4, .wwd-sec2-5, .wwd-sec2-6, .wwd-sec2-7, .wwd-sec2-8{
        width: 100%;
        margin-top: 5%;
        padding: 0 0 3% 0;
    }
    .wwd-card-icon{
        width: 20px;
        color: blue;
    }
    .wwd-sec2-1 h3, .wwd-sec2-2 h3,.wwd-sec2-3 h3, .wwd-sec2-4 h3, .wwd-sec2-5 h3, .wwd-sec2-6 h3, .wwd-sec2-7 h3, .wwd-sec2-8 h3{
        font-size: 15px;
        font-weight: 600;
    }
    .wwd-sec2-1-cont{
        font-size: 10px;
        margin: 3% 5%;
        text-align: justify;
    }
    .whatwedo-dis-sec3{
        display: flex;
        flex-direction: column;
        margin-top: 2%;
        padding: 0px 5% 0 5%;
    }
    .whatwedo-dis-sec3-1{
        width: 100%;
    }
    .whatwedo-dis-sec3-2{
        width: 100%;
    }
    .wwd-dis-heading h3{
        font-size: 20px;
        margin: 0 0 0% 2%;
    }
    .whatwedo-dis-sec3-1 h2{
        font-size: 20px;
        text-align: justify;
    }
    .whatwedo-dis-sec3-1 p{
        font-size: 13px;
        text-align: justify;
        margin: 0 0 5% 0;
    }
    .consulting-left-session{
        display: none;
    }
    .consulting-right-session{
        width: 100%;
        padding: 0 5%;
    }
    .consulting-right-session-content-title{
        font-size: 12px;
        color: #cf7531;
        font-weight: 600;
    }
    .consulting-right-session-title{
        text-align: center;
        margin: 2% 0 0 0;
        font-size: 20px;
    }
    .consulting-right-session-content-para{
        font-size: 12px;
    }
    .offer-content{
        width: 100%;
    }
  }
  @media (max-width : 325px){
    .whatwedo-banner{
        background: url(../bannerimages/whatwedo-banner.jpg)  center/cover;
        width: 100%;
        height: 100px;
    }
    .whatwedo-banner h1{
        color: white;
        text-align: center;
        font-size: 20px;
        padding: 0;
        padding: 5% 0 0 0;
    }
    .whatwedo-banner span{
        color: white;
        font-size: 10px;
    }
    .whatwedo-dis{
        display: flex;
        flex-direction: column;
        margin-top: 2%;
        padding: 0px 5%;
    }
    .whatwedo-dis-sec1-1, .whatwedo-dis-sec1-2{
        width: 100%;
    }
    .wwd-dis-heading h3{
        font-size: 10px;
        margin: 0 0 0 2%;
        text-align: start;
    }
    .whatwedo-dis-sec1-1 h2{
        width: 100%;
        font-size: 15px;
    }
    .whatwedo-dis-sec1-2 p{
        margin: 0%;
        text-align: justify;
        font-size: 10px;
    }
    .wwd-sec2{
        padding: 0 5%;
    }
    .wwd-sec2-1, .wwd-sec2-2,.wwd-sec2-3, .wwd-sec2-4, .wwd-sec2-5, .wwd-sec2-6, .wwd-sec2-7, .wwd-sec2-8{
        width: 100%;
        margin-top: 5%;
        padding: 0 0 3% 0;

    }
    .wwd-card-icon{
        width: 20px;
        color: blue;
    }
    .wwd-sec2-1 h3, .wwd-sec2-2 h3,.wwd-sec2-3 h3, .wwd-sec2-4 h3, .wwd-sec2-5 h3, .wwd-sec2-6 h3, .wwd-sec2-7 h3, .wwd-sec2-8 h3{
        font-size: 15px;
        font-weight: 600;
    }
    .wwd-sec2-1-cont{
        font-size: 10px;
        margin: 3% 5%;
        text-align: justify;
    }
    .whatwedo-dis-sec3{
        display: flex;
        flex-direction: column;
        margin-top: 2%;
        padding: 0px 5% 0 5%;
    }
    .whatwedo-dis-sec3-1{
        width: 100%;
    }
    .whatwedo-dis-sec3-2{
        width: 100%;
    }
    .wwd-dis-heading h3{
        font-size: 20px;
        margin: 0 0 0% 2%;
    }
    .whatwedo-dis-sec3-1 h2{
        font-size: 20px;
        text-align: justify;
    }
    .whatwedo-dis-sec3-1 p{
        font-size: 13px;
        text-align: justify;
        margin: 0 0 5% 0;
    }
    .consulting-left-session{
        display: none;
    }
    .consulting-right-session{
        width: 100%;
        padding: 0 510%;
        
    }
    .consulting-right-session-title{
        text-align: center;
        margin: 2% 0 0 0;
        font-size: 20px;
    }
    .consulting-right-session-content-title{
        font-size: 12px;
        color: #cf7531;
        font-weight: 600;
    }
    .consulting-right-session-content-para{
        font-size: 12px;
    }
    .offer-content{
        width: 100%;
    }

  }

