.speciality-banner{
    background: url(../bannerimages/specialities-Banner.png) center/cover;
    width: 99vw;
    height: 400px;
    box-shadow: inset 0px 0px 100px 100px rgba(0, 0, 0, 0.7);
    padding: 10% 5% 10% 10%;
}
.speciality-banner h1{
    color: white;
    font-size: 50px;
    text-align: right;
}
.speciality-banner p{
    text-align: right;
    color: white;
    font-size: 20px;
    
}
/* .spl-card-sec{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.spl-card{
    width: 25%;
    height: 200px;
    margin: 2%;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    background-color: #243043fc;
    cursor: pointer;
    position: relative;
    perspective: 1000px;
}
.spl-card .spl-card-front p{
    color: white;
    font-size: 20px;
    font-weight: 600;

}
.spl-card-back p{
    color: white;
}
.spl-card-front, .spl-card-back{
    position: absolute;
    backface-visibility: hidden;
}
.spl-card-front{
    border: 1px solid gray;
    backface-visibility: hidden;
    transform: rotateY(0deg);
}
.spl-card-back{
    border: 1px solid gray;
    background-color: WHITE;
    backface-visibility: hidden;
    transform: rotateY(180deg);
}
.spl-card:hover {
    transform: rotateY(180deg);
} */
.spl-card-sec {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .spl-card {
    width: 25%;
    height: 200px;
    margin: 2%;
    position: relative;
    perspective: 1000px; /* Required for 3D effect */
  }
  
  .spl-card-front, .spl-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 10px 10px 20px #babecc, -10px -10px 20px #ffffff;
    cursor: pointer;
  }
  
  .spl-card-front {
    background-color: #243043fc;
    color: white;
  }
  
  .spl-card-front p {
    font-size: 22px;
    font-weight: 600;
    color: white;
  }
  
  .spl-card-back {
    background-color: white;
    color: black;
    transform: rotateY(180deg); /* Hide the back face initially */
    padding: 2%;
  }
  .spl-card-back h5{
      text-align: center ;
      font-size: 15px ;
      font-weight: 600 ;
      color: #243043 ;
      text-shadow: 3px 5px 10px gray;
  }
  .spl-card-back p{
    font-size: 13px;
    text-align: justify;
  }
  
  .spl-card:hover .spl-card-front {
    transform: rotateY(180deg);
  }
  
  .spl-card:hover .spl-card-back {
    transform: rotateY(360deg); /* Show the back face on hover */
  }
  @media (min-width: 1220px) and (max-width: 1440px){
    .spl-card-back h5{

    }
    .spl-card-back p{
      font-size: 12px;
      text-align: justify;
    }
    .spl-card-front p {
      font-size: 20px;
      font-weight: 600;
      color: white;
    }
  }
  @media (min-width: 326px) and (max-width: 768px) {
    .speciality-banner{
      width: 100%;
      height: 200px;
    }
    .speciality-banner h1{
      font-size: 20px;
    }
    .speciality-banner p{
      font-size: 10px;
    }
    .speciality-heading p{
      font-size: 10px;
      text-align: justify;
    }
    .spl-card {
      width: 100%;
      height: 200px;
      margin: 1%;
      position: relative;
      perspective: 1000px; /* Required for 3D effect */
    }
    .spl-card-front p {
      font-size: 15px;
      font-weight: 500;
      color: white;
      text-align: center;
    }
    .spl-card-front, .spl-card-back {
      box-shadow: none;
    }
    .spl-card-back h5{
      font-size: 15px;
      font-weight: 500;
    }
    .spl-card-back p{
      font-size: 12px;
      text-align: justify;
    }
  }
  @media (max-width : 325px){
    .speciality-banner{
      width: 100%;
      height: 100px;
    }
    .speciality-banner h1{
      font-size: 20px;
    }
    .speciality-banner p{
      font-size: 5px;
    }
    .speciality-heading p{
      font-size: 10px;
      text-align: justify;
    }
    .spl-card {
      width: 100%;
      height: 200px;
      margin: 1%;
      position: relative;
      perspective: 1000px; /* Required for 3D effect */
    }
    .spl-card-front p {
      font-size: 15px;
      font-weight: 500;
      color: white;
      text-align: center;
    }
    .spl-card-front, .spl-card-back {
      box-shadow: none;
    }
    .spl-card-back h5{
      font-size: 15px;
      font-weight: 500;
    }
    .spl-card-back p{
      font-size: 12px;
      text-align: justify;
    }

  }
