
section{
   width: 20%;
}
.footer{
    background-color: #367588;
    color: white;
    display: flex;
    justify-content: space-evenly;
    margin: 2% 0 0 0;
    padding: 0 0 2% 0;
}
.footer_section4 {
    display: flex;
    flex-direction: column;
    color:white;
    justify-content: start;
    height: 100%;
}
.footer_section1{
    padding: 2% 0 0 0;
}
.footer_section1 img{
    width: 150px;
}
.footer_section2 h1, .footer_section3 h1, .footer_section2-1 h1{
    font-size: 30px;
}
.det_but{
    width: 120px;
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: rgb(5, 29, 61);
    color: white;
    cursor: pointer;
    margin: 20px 0 0 0;
}
.topics{
    color: white;
    position: relative;  /* Required for positioning the pseudo-element */
    padding: 10px 5px 10px 0;
    cursor: pointer;
    transition: color 0.3s ease; /* Optional: Smooth color transition */
    flex-wrap: wrap;
    font-size: 13px;
    margin-left: 10px;
}
.topics:hover{
    font-size: 15px;
}
    .topics::before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        transition: all 0.3s ease;
        transform: scale(0);
    }
.footer_section4 h1{
    color: white;
    font-size: 30px;
    margin: 0 0 0 10%;
}
.footer_section4{
   margin-top: 1%;
}
.footer_section4 p{
    font-size: 12px;
    color: white;
}
.footer_section4 a{
    color: white;
}
.footer-icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 10% 0 0 0;
    width: 100%;
}
.footer-connection{
    margin-top: 10%;
}
.footer-icons a:hover{
    transform: scale(1.2);
    transition: 0.5s ease-in-out;
}
.Specialities li{
    font-size: 13px;
    padding: 10px 0 0 0;
    color: white;
}
.Specialities ul{
    margin-left: 10px;
}
.Specialities li:hover{
    font-size: 15px;
}
.footer-bottom{
    display: flex;
    justify-content: end;
    padding-right: 2%;
}
.footer-bottom p{
    margin: 1% 0 1% 2%;
}
@media (min-width: 326px) and (max-width: 768px) {
    .footer{
        display: flex;
        flex-direction: column;
        margin: 2% 0 0 0;
        padding: 0 0 2% 0;
    }
    section{
        width: 100%;
     }
     .footer_section1 img{
        width: 100px;
        margin: 0 0 0 40%;
    }
    .footer_section2 li, .footer_section3 li, .footer_section2-1 li{
        text-align: center;
        font-size: 10px;
    }
    .footer_section2 h1, .footer_section3 h1, .footer_section2-1 h1{
        text-align: center;
        font-size: 20px;
    }
    .footer_section2 hr, .footer_section3 hr, .footer_section2-1 hr{
        display: none;
    }
    .det_but{
        width: 100px;
        height: 20px;
        font-size: 10px;
        margin: 5% 0 0 35%;
    }
    .footer_section4 .contactus-details{
        font-size: 20px;
        margin: 0;
        text-align: center;
    }
    .footer_section4 hr{
        display: none;
    }
    .footer_section4{
       margin-top: 2%;
    }
    .footer_section4 p{
        font-size: 10px;
    }
    .footer_section4 h1{
        font-size: 20px;
        margin: 0;
    }
    .footer-bottom{
        display: flex;
        justify-content: start;
        padding-right: 2%;
    }
    .footer-bottom p{
        margin: 1% 0 1% 2%;
        font-size: 10px;
    }
}
@media (max-width: 325px){
    .footer{
        display: flex;
        flex-direction: column;
        margin: 2% 0 0 0;
        padding: 0 0 2% 0;
    }
    section{
        width: 100%;
     }
     .footer_section1 img{
        width: 100px;
        margin: 0 0 0 40%;
    }
    .footer_section2 li, .footer_section3 li, .footer_section2-1 li{
        text-align: center;
        font-size: 10px;
    }
    .footer_section2 h1, .footer_section3 h1, .footer_section2-1 h1{
        text-align: center;
        font-size: 20px;
    }
    .footer_section2 hr, .footer_section3 hr, .footer_section2-1 hr{
        display: none;
    }
    .det_but{
        width: 100px;
        height: 20px;
        font-size: 10px;
        margin: 5% 0 0 35%;
    }
    .footer_section4 .contactus-details{
        font-size: 20px;
        margin: 0;
        text-align: center;
    }
    .footer_section4 hr{
        display: none;
    }
    .footer_section4{
       margin-top: 2%;
    }
    .footer_section4 p{
        font-size: 10px;
    }
    .footer_section4 h1{
        font-size: 20px;
        margin: 0;
    }
    .footer-bottom{
        display: flex;
        justify-content: center;
        padding-right: 2%;
    }
    .footer-bottom p{
        margin: 1% 0 1% 2%;
        font-size: 10px;
    }

}
