.portion1-2{
    padding: 2%;
    height: 100%;
    border: 1px solid rgba(188, 185, 185, 0.708);
    border-radius: 10px;
    margin: 2% 0% 0 0 ;
    width: 100%;
    box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
}
.portion1-2 form{
    width: 100%;
    height: 100%;

    padding: 2%;
}
.portion1-2 label{
    font-size: 20px;

}
.portion1-2 input{
    padding: 2%;
    width: 80%;
    margin-bottom: 3%;
    height: 40px;
    border: 1px solid rgba(203, 202, 202, 0.655);
    border-radius: 5px;
}
.portion1-2 input:hover{
    border: 1px solid #243043;
}
.portion1-2 button{
    width: 200px;
    height: 50px;
    background-color: #243043;
    color: white;
    border-radius: 5px;
}