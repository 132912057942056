.downloadcontainer{
    width: 100%;
    background-color: rgb(61, 61, 61);
    margin-top: 2%;
    padding: 2% 0 5% 0;
}
.downloadcontainer button{
    width: 150px;
    height: 50px;
    border-radius: 30px;
}
.downloadcontainer button:hover{
    background-color: black;
    color: white;
}
@media (min-width: 326px) and (max-width: 768px){
    .downloadcontainer h1{
        font-size: 20px;
    }
    .downloadcontainer p{
        font-size: 10px;
    }
    .downbtn{
        width: 100px;
        height: 20px;
        border-radius: 30px;
        font-size: 10px;
    }
}
@media (max-width : 325px){
    .downloadcontainer h1{
        font-size: 20px;
    }
    .downloadcontainer p{
        font-size: 10px;
    }
    .downbtn{
        width: 50px;
        height: 20px;
        border-radius: 30px;
        font-size: 10px;
    }
}
