*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
}


Link{
  list-style-type:none;
  text-decoration: none;
  list-style: none;
  color: orange;
}
button{
  border: none;
  cursor: pointer;
}
a{
  list-style-type: none;
  text-decoration: none;
}
p{
  color: rgb(46, 46, 46);
}
h1{
  font-weight: 600;
  color: #083b4a;

}
li{
  list-style: none;
  text-decoration: none;
}
.important-styles{
  box-shadow:10px 10px 20px #babecc, -10px -10px 20px #ffffff;
  transition: 0.5s ease;
 transform: scale(1.1);
 color: rgba(177, 177, 177, 0.732);
 
}
abbr{
  text-decoration: none;
}
@media (max-width: 700px) {
  h1{
    font-size: 15px;
  }
  
}