* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

nav {
    width: 100vw;
    height: 80px;
    box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.637);
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 1000;
    padding: 0 20px;
}

.logo {
    width: 120px;
    margin-left: 8%;
}

.options {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 65%;
    position: relative;
}

.li {
    border-radius: 10px 10px 0px 0px;
    background-color: transparent;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: black;
    padding: 20px 10px;
    margin: 0 15px;
    position: relative;
    cursor: pointer;
    transition: color 0.3s ease;
    font-size: 15px;
}
.li:hover {
    transform: scale(1.3); /* Reduce the scaling to prevent overflowing */
    color: #cf7531; /* Add color change for better visual feedback */
    transition: transform 0.1s ease-in-out, color 0.3s ease-in-out;
    z-index: 10;
}

/* .li:hover::before {
    transform: scale(1);
}

.li::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgb(52, 0, 137);
    transition: all 0.3s ease;
    transform: scale(0);
} */

.nav-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-right: 20px;
}

.nav-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
    transition: 0.4s;
}
.sub-option{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: absolute;
    margin-top: 1.5%;
    border: 1px solid rgb(201, 201, 201);
    height: 150px;
    background-color: white;
    display: none;
    z-index: 10;
    padding: 1%;
    border-radius: 5px;
    
}
.sub-option a{
    padding: 5px 10px;
    margin-top: 2%;
    width: 100px;
    margin-left: 1%;
}
.sub-option li{
    padding: 5px;
    width: 100%;
    color: black;
    text-align: center;
    font-size: 12px;
}
.option1:hover .sub-option, .option2:hover .sub-option, .option3:hover .sub-option, .option4:hover .sub-option, .option5:hover .sub-option, .option6:hover .sub-option{
    display:flex;
}

.sub-option li:hover{
    transform: scale(1.1);
    font-weight: 600;
    color: #cf7531; /* Add color change for better visual feedback */
    transition: transform 0.1s ease-in-out, color 0.1s ease-in-out;
}
.sub-option li:hover abbr{
    transform: scale(1.1);
    font-weight: 600;
    color: #cf7531; 
    transition: transform 0.1s ease-in-out, color 0.1s ease-in-out;
}
abbr {
    position: relative;
}
/* 
abbr:hover::after {
    width: 100px;
    height: 20px;
    font-size: 10px;
    position: absolute;
    bottom: -300%;
    left: 0%;
    right: 5%;
    display: block;
    padding: 10px;
    background: white;
    content: attr(title);
} */

/* Media Queries for Mobile View */
.mobile-nav{
    width: 100vw;
    display: none;
}
.dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    list-style: none;
  }
  
  .dropdown:hover .dropdown-menu {
    display: block;
  }
@media (max-width: 768px) {
    .options {
        display: none;
        flex-direction: column;
        width: 80%;
        text-align: start;
        position: absolute;
        top: 80px;
        left: 0;
        background-color: white;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    }
    .mobile-nav-option1, .mobile-nav-option2, .mobile-nav-option3, .mobile-nav-option4, .mobile-nav-option5{
        border-bottom: 1px solid gray;
    }
    .mobile-nav-option1 a, .mobile-nav-option2 a, .mobile-nav-option3 a, .mobile-nav-option4 a, .mobile-nav-option5 a{
        font-size: 12px;
        padding: 0 10% 0 0;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
        text-align: center;
    }
    .mobile-nav-option2-menu{
        width: 100%;
        align-content: center;
    }
    .mobile-nav-option2-main{
        height: 30px;
        display: flex;
        justify-content: center;
    }
    .options.active {
        display: flex;
    }

    .li {
        margin: 10px 0;
        padding: 20px;
    }

    .nav-toggle {
        display: flex;
    }
    .navbar{
        display: none;
    }
    .mobile-nav{
        width: 100vw;
        display: flex;
        justify-content: space-between;
        padding: 0 2%;
        position: fixed;
        z-index: 1000;
    }
    .mobile-nav-logo{
        width: 30%;
        height: 100%;
    }
    .mobile-nav-menu{
        width: 20%;
        border: 1px solid blue;
        height: 100%;
    }
    .nav-toggle {
        width: 15%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        margin: 0% 0 0% 0;
        padding: 2% 0 0 0;
    }
    
    .nav-toggle .bar {
        width: 100%;
        height: 3px;
        background-color: black;
        margin: 5px 0;
        transition: 0.4s;
    }

    .mobile-sub-options button{
        width: 100%;
        border: 0.5px solid rgb(203, 201, 201);
        font-size: 10px;
        text-align: start;
        padding: 0 0 0 15%;
        height: 25px;
    }
    .dropdown-menu {
        position: relative;
        box-shadow: none;
      }

      .options li{
        border-bottom: 1px solid gray;
        padding: 1% 0 1% 5%;
      }
}

/* Hover effect for toggle */
.navbar.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.navbar.active .bar:nth-child(2) {
    opacity: 0;
}

.navbar.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}
